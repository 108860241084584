export enum PlaylistsTabsTypes {
  new = "new",
  saved = "saved",
  archived = "archived",
}

export enum ProAction {
  SAVE = "SAVE",
  UNSAVE = "UNSAVE",
  ARCHIVE = "ARCHIVE",
  UNARCHIVE = "UNARCHIVE",
  OPEN = "OPEN",
  PLAY = "PLAY",
  VIEW = "VIEW",
  MESSAGE = "MESSAGE",
  LIKE = "LIKE",
  SUPERLIKE = "SUPERLIKE",
}

export const PlaylistsTabsItems = [
  {
    type: PlaylistsTabsTypes.new,
    label: "New",
    visibleTypes: [PlaylistsTabsTypes.new],
  },
  {
    type: PlaylistsTabsTypes.saved,
    label: "Saved",
    visibleTypes: [PlaylistsTabsTypes.saved],
  },
  {
    type: PlaylistsTabsTypes.archived,
    label: "Archived",
    visibleTypes: [PlaylistsTabsTypes.archived],
  },
];

export interface PlaylistsFilter {
  createdHoursTillNow?: number;
  genres?: string[];
  saved?: boolean;
  archived?: boolean;
}
